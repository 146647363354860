<template>
  <div
    id="app-base"
    style="min-height: 100vh"
    :class="{ 'app-mobile': $screen.xs }"
  >
    <Modal v-if="isModalOpen" :modalData="modalData" />
    <Header :hideHeader="shouldHideHeader" />
    <FloatingPremium
      class="floating-container"
      @activeFloatingPremium="hasActiveFloatingPremium"
    />
    <Banner />
    <div>
      <InformationButton
        class="fixed floatingButton firstFloatingButton"
      />
    </div>
    <div
      class="main-content-wrapper"
      id="floatingPremiumHeightTarget"
      :class="{ 'no-scroll': $screen.xs && isFloatingPremiumActive }"
    >
      <div class="main-content content-fullheight">
        <router-view />
      </div>
      <Container :backgroundAlt="true">
        <Footer />
      </Container>
    </div>

    <transition name="fade">
      <div
        v-show="shouldShowOverlay"
        class="greyed-out-overlay absolute-stretch"
      />
    </transition>
    <div id="loading-indicator" class="loading-bar" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "@/components/shared/Modal.vue";
import InformationButton from "@/components/shared/InformationButton.vue";
import CollectivityLabel from "@/constants/collectivityLabel";
import ModalConst from "@/constants/modal";
import Header from "@/components/shared/2.0-import/Header.vue";
import FloatingPremium from "@/components/shared/FloatingPremium.vue";
import Banner from "@/components/shared/Banner.vue";
import ModalDataFactory from "@/components/modals/ModalDataFactory";
import { cookieBanner } from "@/mixins/cookieBanner.js";
import { googleAnalyticsTag } from "@/mixins/googleAnalyticsTag.js";
import { googletagmanager } from "@/mixins/googleTagManager.js";
import { siteimprove } from "@/mixins/siteimprove.js";
import Footer from "@/components/shared/Footer.vue";
import Container from "@/components/shared/2.0-import/Container.vue";
import { googleSearchConsole } from "./mixins/googleSearchConsole";

export default {
  name: "App",
  mixins: [googleAnalyticsTag, googletagmanager, siteimprove, cookieBanner, googleSearchConsole],
  components: {
    Modal,
    Header,
    FloatingPremium,
    Banner,
    InformationButton,
    Footer,
    Container,
  },
  data() {
    return {
      isFloatingPremiumActive: false
    };
  },
  computed: {
    ...mapGetters({
      modalData: "modalData",
      isModalOpen: "isModalOpen",
      shouldShowOverlay: "shouldShowOverlay",
      labelsLoading: "labelsLoading",
      collectivityLabelSetting: "labelSettings",
      revisitCookieName: "revisitCookieName",
    }),
    shouldHideHeader() {
      return this.$screen.xs && this.isFloatingPremiumActive;
    },
  },
  methods: {
    hasActiveFloatingPremium(value) {
      this.isFloatingPremiumActive = value;
    },
  },
  beforeMount() {
    this.$store
      .dispatch(CollectivityLabel.Actions.SET_SETTINGS, window.location)
      .then(() => {
        document.documentElement.setAttribute(
          "theme",
          this.collectivityLabelSetting.theme
        );

        setTimeout(() => {
          const currentRouteName = this.$router.currentRoute.name;
          if (
            this.$cookies.isKey(this.revisitCookieName) &&
            (currentRouteName === "home" ||
              currentRouteName === "contract-overview")
          ) {
            this.$store.dispatch(ModalConst.Actions.OPEN, {
              modalData: ModalDataFactory.createRevisit(),
              isPersistent: true,
            });
          }
        }, 800);
      });
  }
    
};
</script>

<style lang="scss">
@import "@/assets/stylesheets/main.scss";

#app-base {
  background-color: var(--color-white);
}

.absolute {
  position: absolute;
  bottom: 0;
}

.z-index-10 {
  z-index: 10;
}

.loading-bar {
  position: fixed;
  z-index: 11;
  min-height: 11px !important;
  height: 11px !important;
  width: 100%;
  bottom: 0;
}

.greyed-out-overlay {
  background-color: rgba(75, 75, 75, 0.4);
  z-index: 80;
  cursor: progress;
}
.absolute-stretch {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.floatingButton {
  z-index: 2147482999;
  &.firstFloatingButton {
    right: 22px;
    bottom: 22px;
  }
  &.secondFloatingButton {
    right: 92px;
  }
}

.floating-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1040px;
}

.loading-icon {
  will-change: transform;
  animation: appear 2s 0.6s 1 forwards;
  opacity: 0;
}

.no-scroll {
  position: fixed;
}

@keyframes appear {
  to {
    opacity: 0.8;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}

.content-fullheight {
  min-height: calc(100vh - 225px);
}
</style>
